import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
// import L from 'leaflet'
import '@supermap/iclient-leaflet'
import './styles/index.less'
import 'leaflet/dist/leaflet.css'
import '@supermap/iclient-leaflet'
import "@/utils/rem.js"
import * as echarts from 'echarts'
// import 'echarts-liquidfill'
require('echarts/lib/component/grid');
// Vue.use(L)
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
