<template>
  <div id="map">
    <div class="tools">
      <div class="switchTab">
        <div
          :class="[activedTab == 'jd' ? 'jd_tab actived_Tab' : 'jd_tab']"
          @click="changeData('jd')"
        >
          街道地图
        </div>
        <div
          :class="[activedTab == 'yq' ? 'yq_tab actived_Tab' : 'yq_tab']"
          @click="changeData('yq')"
        >
          园区地图
        </div>
      </div>
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="value2" placeholder="请选择用房类型">
        <el-option
          v-for="item in houseTypeArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button @click="search" class="btn">查询</el-button>
      <el-button @click="clearLayers" class="btn">重置</el-button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
// 加载不同类别的数据的时候 是否可以显示不同的颜色,根据day05的课程
// 根据服务器图设置的颜色,geojson渲染的时候直接用设置好的颜色
import L from 'leaflet'
import '@supermap/iclient-leaflet'
import 'leaflet-editable'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
// import { LabelTextCollision } from '../../public/js/label'
export default {
  props:{
    type:{
      type:String,
      default:''
    }
  },
  watch:{
   type:function(newval) {
       this.value2 = newval;
      // this.query()
     }
 },
  data () {
    return {
      activedTab: 'jd',
      map_qu: `${window.BASE_URL.superMapUrl}/iserver/services/map-lg/rest/maps/qu@lg`,
      map_jd: `${window.BASE_URL.superMapUrl}/iserver/services/map-lg/rest/maps/jd@lg`,
      map_qujie:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-lg/rest/maps/jd@lg`,
      map_yuanqu:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-lg/rest/maps/park@lg`,
      map_build:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-lg/rest/maps/house@lg`,
      map_dataset:
        `${window.BASE_URL.superMapUrl}/iserver/services/data-czc_zhuzhai_ld/rest/data`,
      map: null,
      data_url: `${window.BASE_URL.superMapUrl}/iserver/services/data-lg/rest/data`,
   
  //  =============================坪山====================================
      map_gaoxin:`${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gxjsqy@ps`,
      map_sishang:`${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gyqy@ps`,

      resultLayer: '',
      querygroup: '',
      querygroup2: '',
      cluster: '',
      options: [
        {
          value: '龙城街道',
          label: '龙城街道'
        },
        {
          value: '宝龙街道',
          label: '宝龙街道'
        },
        {
          value: '坪地街道',
          label: '坪地街道'
        },
        {
          value: '园山街道',
          label: '园山街道'
        },
        {
          value: '横岗街道',
          label: '横岗街道'
        },
        {
          value: '南湾街道',
          label: '南湾街道'
        },
        {
          value: '布吉街道',
          label: '布吉街道'
        },
        {
          value: '吉华街道',
          label: '吉华街道'
        },
        {
          value: '坂田街道',
          label: '坂田街道'
        },
        {
          value: '平湖街道',
          label: '平湖街道'
        },
        {
          value: '龙岗街道',
          label: '龙岗街道'
        }
      ],
      houseTypeArr: [
        {
          value: '厂房',
          label: '厂房'
        },
        {
          value: '研发用房',
          label: '研发用房'
        },
        {
          value: '产业配套用房',
          label: '产业配套用房'
        },
        {
          value: '物流仓储用房',
          label: '物流仓储用房'
        },
        {
          value: '商业用房',
          label: '商业用房'
        }
      ],
      value: '',
      value2: '',
      jiedao: '',
      yuanqu: '',
      build:''
    }
  },
  methods: {
    changeData (name) {
      this.activedTab = name
      // var jiedao = L.supermap.tiledMapLayer(this.map_jd)
      // var yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      switch (name) {
        case 'jd':
          if(this.map.hasLayer(this.yuanqu)) {
            this.map.removeLayer(this.yuanqu)
          }
          this.jiedao.addTo(this.map)
          break
        case 'yq':
          console.log('enter');
          // this.map.removeLayer(jiedao)
          // jiedao.removeFrom(this.map)
          // if(this.map.hasLayer(this.jiedao)) {
          //   this.map.removeLayer(this.jiedao)
          // }
          this.yuanqu.addTo(this.map)
          break

        default:
          break
      }
    },
    init () {
      const vm = this
      // eslint-disable-next-line no-undef
      var dianzitu = L.supermap.tiandituTileLayer({
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })

      var tianditu = L.supermap.tiandituTileLayer({
        layerType: 'img',
        url:
          'http://t0.tianditu.gov.cn/img_c/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var labelMap = L.supermap.tiandituTileLayer({
        isLabel: true,
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var tdtDz = L.layerGroup([labelMap, dianzitu])
      var tdtYx = L.layerGroup([tianditu, labelMap])
      // var qu = L.supermap.tiledMapLayer(this.map_qu)
      this.jiedao = L.supermap.tiledMapLayer(this.map_jd)
      var qujie = L.supermap.tiledMapLayer(this.map_qujie)
     this.yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      this.build = L.supermap.tiledMapLayer(this.map_build)
      var gaoxin = L.supermap.tiledMapLayer(this.map_gaoxin)
      var sishang = L.supermap.tiledMapLayer(this.map_sishang)
      // var labelTextCollision = L.LabelTextCollision({
      //   collisionFlg: true
      // })
      this.map = L.map('map', {
        preferCanvas: true,
        crs: L.CRS.EPSG4326,
        center: [22.7152291384486,114.347931662042],
        maxZoom: 16,
        zoom: 11,
        // layers: [tdtYx, qujie,gaoxin],
        layers: [tdtYx,gaoxin,sishang],
         zoomControl:false,
        control: ['zoomControl'],
        // renderer: new L.LabelTextCollision({
        //   collisionFlg: true
        // })
        // maxBounds: [
        //   [22.58450201200003, 114.04499371700001],
        //   [22.816480775000059, 114.35167101400009]
        // ]
      })

      var baseMaps = {
        电子地图: tdtDz,
        影像图: tdtYx
      }
      var overlayMaps = {
        街道: this.jiedao,
        区界: qujie,
        园区: this.yuanqu,
        楼栋: this.build
      }
      console.log('baseMap :>> ', baseMaps);
      console.log('overlayMaps :>> ', overlayMaps);

      // L.control.layers(baseMaps, overlayMaps).addTo(this.map)
      L.marker([22.7152291384486,114.347931662042]).addTo(this.map)
      this.querygroup = L.layerGroup().addTo(this.map)
      this.querygroup2 = L.layerGroup().addTo(this.map)
      this.jsonData = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              name: '我是点1'
            },
            geometry: {
              type: 'Point',
              coordinates: [114.06276226043701, 22.53883909468463]
            }
          },
          {
            type: 'Feature',
            properties: {
              name: '我是点2'
            },
            geometry: {
              type: 'Point',
              coordinates: [114.06688213348389, 22.542802784422523]
            }
          },
          {
            type: 'Feature',
            properties: {
              name: '我是点3'
            },
            geometry: {
              type: 'Point',
              coordinates: [114.07138824462889, 22.53856163214095]
            }
          },
          {
            type: 'Feature',
            properties: {
              name: '我是点4'
            },
            geometry: {
              type: 'Point',
              coordinates: [114.06851291656494, 22.531862724356042]
            }
          }
        ]
      }
      // L.geoJSON(this.jsonData, {
      //   pointToLayer: (geoJsonPoint, latlng) => {
      //     return L.circleMarker(latlng, {
      //       text: 'AA'
      //     })
      //   }
      // }).addTo(vm.map)
      // 改写为featureGroup
      // this.cluster = L.featureGroup().addTo(this.map)
      //   this.cluster = L.markerClusterGroup().addTo(this.map)
      //   this.jsonData.features.forEach(feature => {
      //     L.marker([
      //       feature.geometry.coordinates[1],
      //       feature.geometry.coordinates[0]
      //     ]).addTo(this.cluster)
      //   })
      this.map.on('click', function (evt) {
        vm.x = evt.latlng.lng
        vm.y = evt.latlng.lat
        vm.latlng = evt.latlng
        vm.Sqlquery()
      })
    },
    search () {
      if (this.value === '') {
        this.query()
      } else {
        this.flyTo()
      }
    },
    /**
     * 描述
     * L.geoJSON(<Object> geojson?, <GeoJSON options> options?)
     * 创建 GeoJSON 图层。可选择接受 GeoJSON 格式的对象以显示在地图上（您也可以稍后使用addData方法添加它）和一个options对象。
     * @date 2022-04-12
     * @description 根据下拉框街道,可查询园区(龙岗园区)或者可查询企业(龙岗产业用房) 修改name与datasetNames
     * @update 2022-04-14 switchcase来分别渲染类,根据字段来渲染;
     * @returns {any}
     */
    query () {
      if (this.querygroup != null) {
        this.querygroup.clearLayers()
      }
      const vm = this
      var attributeFilter
      if(this.value2=='') { 
        //选择全部的时候 查询所有 直接把园区图层加载出来就是全部
        this.build.addTo(this.querygroup)

      }
      if (this.value == '') {
        attributeFilter = `ztlx="${this.value2}"`
      } else if (this.value2 == '') {
        attributeFilter = `jd="${this.value}"`
      } else {
        attributeFilter = `jd="${this.value}" AND ztlx="${this.value2}"`
      }
      // eslint-disable-next-line no-undef
      var sqlParam = new SuperMap.GetFeaturesBySQLParameters({
        queryParameter: {
          name: '龙岗产业用房', // dataset名称即可
          attributeFilter // 记得值要加上引号
          // attributeFilter: `jd="${this.value}"` //正确方式
        },
        toIndex: 999,
        datasetNames: ['lg:龙岗产业用房'] // [数据源:数据集]
      })
      L.supermap
        .featureService(this.data_url)
        .getFeaturesBySQL(sqlParam, function (serviceResult) {
          this.resultLayer = L.geoJSON(serviceResult.result.features, {
            // 查询出来的信息展示
            // pointToLayer (geoJsonPoint, latlng) {
            //   return L.marker(latlng, {
            //     text: geoJsonPoint.properties.ADDRESS
            //   })
            // },
            /**
             * 描述 根据数据库不同字段渲染不同数据
             * @date 2022-04-14
             * @returns {any}
             */
            style: function (geoJsonFeature) {
              switch (geoJsonFeature.properties.SYYT) {
                case '标准厂房':
                  return { color: 'red' }
                case '办公用房':
                  return { color: 'pink' }
                case '物流用房':
                  return { color: 'yellow' }
                default:
                  return { color: 'black' }
              }
            },
            onEachFeature: function (feature, layer) {
              layer.bindTooltip('具体地址: ' + feature.properties.ADDRESS, {
                // permanent: true
              })
              // layer.bindPopup('具体地址: ' + feature.properties.ADDRESS)
            }

            // onEachFeature: function (feature, layer) {
            //   layer.bindPopup('名称: ' + feature.properties.NAME)
            // }
          }).addTo(vm.querygroup)
        })
    },
    /**
     * 描述 点搜索的时候先来查询区的中心点,然后放大定位到这,在进行下一步查询
     * @date 2022-04-12
     * @returns {any}
     */
    flyTo () {
      const vm = this
      var position
      // eslint-disable-next-line no-undef
      var sqlParam = new SuperMap.GetFeaturesBySQLParameters({
        queryParameter: {
          name: '龙岗街道边界', // dataset名称即可
          attributeFilter: `jdname="${this.value}"` // 记得值要加上引号
        },
        toIndex: 999,
        datasetNames: ['lg:龙岗街道边界'] // [数据源:数据集]
      })
      L.supermap
        .featureService(this.data_url)
        .getFeaturesBySQL(sqlParam, function (serviceResult) {
          this.resultLayer = L.geoJSON(serviceResult.result.features, {
            onEachFeature: function (feature, layer) {
              position = layer.getBounds().getCenter() // 获取查询区域返回的中心点,为了flyto
            }
          })
          vm.map.flyTo(position, 13)
        })
      vm.query()
    },
    /**
     * 描述 点击查询调用的方法
     * @date 2022-04-12
     * @returns {any}
     */
    Sqlquery () {
      const vm = this
      var popupContent = null
      var marker
      //   var currentLayer
      if (this.querygroup2 != null) {
        this.querygroup2.clearLayers()
      }
      var point = L.circle([vm.y, vm.x], {
        radius: 1
      })
      // eslint-disable-next-line no-undef
      var geometryParam = new SuperMap.GetFeaturesByGeometryParameters({
        datasetNames: [
          'lg:龙岗园区',
          'lg:龙岗产业用房'
          // 'baoan_1:T20201022_宝安区园区_1'
          // 'yuanqu:bldg'
          // 顺序有关系,把楼栋放在后面,那么点击楼栋查询的时候才能查到.
          // 如果放在前面,那么点击查询的时候 会查到园区,点不到楼栋,被覆盖了.
          // 放在后面的查询层级比较高
          // "zhuzhaibianjie:GCS_2000_所有住宅小区的边界20201019",
        ], // 这里data就是数据源  资溪就是数据集
        geometry: point,
        spatialQueryMode: 'INTERSECT'
      })
      L.supermap
        .featureService(this.data_url)
        .getFeaturesByGeometry(geometryParam, function (serviceResult) {
          if (serviceResult.result) {
            L.geoJSON(serviceResult.result.features, {
              onEachFeature: function (feature, layer) {
                console.log(layer)
                // currentLayer = layer
                // vm.getBuildingsByParkBounds(currentLayer)

                popupContent =
                  '所属园区' +
                  '：' +
                  feature.properties.PARK_NAME +
                  '<br>' +
                  '详细地址' +
                  ':' +
                  feature.properties.ADDRESS +
                  '<br>' +
                  '用地类型' +
                  ':' +
                  feature.properties.LAND_TYPE +
                  '<br>' +
                  '用房类型' +
                  ':' +
                  feature.properties.ZTLX
              }
            }).addTo(vm.querygroup2)
          }
          if (popupContent != null) {
            marker = L.marker(vm.latlng).addTo(vm.querygroup2)
            marker.bindPopup(popupContent).openPopup()
          }
        })
    },
    clearLayers () {
      this.map.flyTo([22.7152291384486,114.347931662042], 11)
      this.querygroup.clearLayers()
      this.value = ''
      this.value2 = ''
    }
  },
  mounted () {
    this.type = this.industryType;
    console.log(this.$parent);
    console.log('L.map :>> ', this.type)
    this.init()
  }
}
</script>

<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .tools {
    position: relative;
    z-index: 999;
    margin-top: 30px;
    margin-left: 80px;
    .switchTab {
      width: 180px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #e7e8ee;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      text-align: center;
      .jd_tab,
      .yq_tab {
        background: #fff;
        border-radius: 17px;
        width: 50%;
        position: relative;
        z-index: 9;
      }
      .actived_Tab {
        // position: absolute;
        // left: 0;
        width: 90px;
        height: 34px;
        background: #4e81fd;
        border-radius: 17px;
        color: #fff;
        // transform:translate(-50%,-50%);
        transition-duration: 0.3s;
        z-index: 11;
      }
    }
    .btn {
      margin-left: 10px;
    }
  }
}
</style>
