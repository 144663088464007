<template>
  <div class="layout-container">
    <img class="bg-img" src="@/assets/images/main/center/ditu.png" alt="" />
    <div class="top-container">
      <div class="main-bg">
        <img src="@/assets/images/charts/daohang.png" alt="" />
      </div>
      <div class="nav-container">
        <div
          class="nav-item"
          v-for="(item, index) in navUrl"
          :key="index"
          @click="selectPath(index,item.path,item.hash)"
        >
        <!-- 好需要路由判断 建议直接路由判断-->
        <span :class="{select:curentIndex == item.hash}">{{item.text}}</span>
          <!-- <div class="nav-img">
            <img :src="item.currentUrl" alt="" v-if="curentIndex==index"/>
            <img v-else :src="item.unSelect" alt="" />
          </div>
          <transition name="el-fade-in-linear">
            <span v-show="curentIndex == index">综合一张图</span>
          </transition> -->
        </div>
      </div>
    </div>
    <div class="center-container">
      <div class="main-center">
        <router-view :key="key"></router-view>
      </div>
    </div>
    <div class="bottom-container">
      <img class="dibu-img" src="@/assets/images/common/dibu.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "layout",
  data() {
    return {
      // curentIndex: document.location.hash,
      navUrl:[
      {
          path:'/main/chartsB',
          hash:'chartsB',
          currentUrl:require("@/assets/images/charts/l-fenbu.png"),
          unSelect:require("@/assets/images/charts/fenbu.png"),
          text:'领导驾驶舱'
        },
      {
          path:'/main/IndustrialChain',
          hash:'IndustrialChain',
          currentUrl:require("@/assets/images/charts/l-fang.png"),
          unSelect:require("@/assets/images/charts/fang.png"),
          text:'9+2+N产业链'
        },
        {
          path:'/main/IndustryDistributionMap',
          hash:'IndustryDistributionMap',
          currentUrl:require("@/assets/images/charts/l-yizhangtu.png"),
          unSelect:require("@/assets/images/charts/yizhangtu.png"),
          text:'产业纵览'
        },

          {
            path:'/main/IndustryLanding',
            hash:'IndustryLanding',
          currentUrl:require("@/assets/images/charts/l-qiye.png"),
          unSelect:require("@/assets/images/charts/qiye.png"),
          text:'产业用地'
        },
        {
          path:'/main/industryHousing',
          hash:'IndustryHousing',
          currentUrl:require("@/assets/images/charts/l-di.png"),
          unSelect:require("@/assets/images/charts/di.png"),
          text:'产业用房'
        },
        {
          path:'/main/enterpriseInfo',
          hash:'enterpriseInfo',
          currentUrl:require("@/assets/images/charts/l-ren.png"),
          unSelect:require("@/assets/images/charts/ren.png"),
          text:'一企一档'
        },

      ]
    };
  },
  computed: {
    curentIndex() {
      return this.$route.name
    },
    key() {
        return this.$route.path
      },
  },
  methods: {
    selectPath(idx,path,hash) {
      this.curentIndex = hash
      this.$router.push(path)
    }
  },
};
</script>
<style lang="less" scoped>
.layout-container {
  // height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // background-size: 100% 100%;
  // background: url("../assets/images/main/center/ditu.png");
  // background-repeat: no-repeat;
  .center-container {
    flex-grow: 1;
    width: 100%;
  .main-center{
    height: 100%;
    width: 100%;
  }
   
  }
  .top-container {
    width: 100%;
    height: 5.4375rem;
    position: relative;
    .main-bg {
      width: 100%;
      // height: 80px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .nav-container {
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 34.375rem;
      // padding-bottom: 2.5rem;
      .nav-item {
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 1rem;
        margin-right: 3.125rem;
        cursor: pointer;
        .nav-img{
          height: 2.25rem;
          img{
            height: 100%;
          }
        }
        span {
          margin-left: 0.625rem;
          font-size: .875rem;
          // font-family: Source Han Sans CN;
          font-weight: bold; 
          color: #ffffff;
          letter-spacing: .0938rem;
          &.select{
            color: #159aff;
            text-shadow: 0px 0px 4px rgba(9, 34, 54, 0.2);

          }
        }
      }
    }
  }
  .bottom-container {
    width: 100%;
    // margin-top: 1.25rem;
    position: relative;
    // display: flex;
    // justify-content: center;
    .dibu-img {
      width: 100%;
      height:1.25rem;
      // position: absolute;
      // bottom: 0;
      // left: 0;
    }
  }
}
</style>