<template>
  <div class="home">
    <el-container>
      <el-aside>
        <div class="daohang">
          <img src="../assets/images/home/dh.png" alt="" />
        </div>
        <div class="typeList">
          <div
            :class="[activedType == index ? 'itemOne actived' : 'itemOne']"
            v-for="(item, index) in typeList"
            :key="index"
            @click="selectType(item,index)"
          >
            <div class="icon">
              <img :src="getpath2(index < 9 ? '0' + (index + 1) : index + 1)" />
            </div>
            <div class="word">{{ item.title }}</div>
          </div>
        </div>
      </el-aside>
      <el-container class="right-container">
        <el-header class="right-header">
          <div class="dataItem" v-for="(item, index) in showList" :key="index">
            <img :src="getpath(index < 9 ? '0' + (index + 1) : index + 1)" />
            <div class="right">
              <div class="num">
                {{ item.num }}
                <span>{{ item.unit }}</span>
              </div>
              <div class="descr">{{ item.title }}</div>
            </div>
          </div>
        </el-header>
        <el-main><Map ref="map" :type="industryType"></Map></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Map from '@/components/map'
export default {
  components: {
    Map
  },
  name: 'Home',
  data () {
    return {
      industryType:'0',
      activedType: '0',
      showList: [
        {
          num: '253',
          unit: '个',
          title: '企业数量'
        },
        {
          num: '254',
          unit: '万元',
          title: '上年度产值或营收'
        },
        {
          num: '226',
          unit: '万元',
          title: '上年度纳税额'
        },
        {
          num: '123',
          unit: '个',
          title: '从业人员'
        },
        {
          num: '6545',
          unit: '㎡',
          title: '所占产业用地面积'
        },
        {
          num: '4355',
          unit: '㎡',
          title: '所占产业用房面积'
        },
        {
          num: '12',
          unit: '万元/个',
          title: '人均产值'
        },
        {
          num: '45',
          unit: '万元/㎡',
          title: '地均产值'
        },
        {
          num: '32',
          unit: '万元/㎡',
          title: '房均产值'
        },
        {
          num: '455',
          unit: '万元',
          title: '能耗'
        },
        {
          num: '4：3',
          unit: '',
          title: '能耗产值比'
        },
        {
          num: '325',
          unit: '个',
          title: '专利数量'
        },
        {
          num: '233',
          unit: '万元',
          title: '投资金额'
        },
        {
          num: '451',
          unit: '万元',
          title: '融资金额'
        }
      ],
      typeList: [
        {
          title: '全部',
          value:''
        },
        {
          title: '宽带网络通信',
          value:'厂房'
        },
        {
          title: '半导体与集成电路',
          value:'研发用房',
        },
        {
          title: '超高清视频显示',
          value:'产业配套用房'
        },
        {
          title: '智能终端',
          value:'物流仓储用房'
        },
        {
          title: '智能传感器',
          value:'商业用房'
        },
        {
          title: '软件与信息技术服务'
        },
        {
          title: '数字创意'
        },
        {
          title: '现代时尚'
        },
        {
          title: '工业母机'
        },
        {
          title: '智能机器人'
        },
        {
          title: '激光与增材制造'
        },
        {
          title: '精密仪器设备'
        },
        {
          title: '新能源'
        },
        {
          title: '安全应急与节能环保'
        },
        {
          title: '智能网联汽车'
        },
        {
          title: '新材料'
        },
        {
          title: '高端医疗器械'
        },
        {
          title: '生物医疗'
        },
        {
          title: '大健康'
        },
        {
          title: '海洋经济'
        }
      ]
    }
  },
  methods: {
    selectType (item,index) {
      this.activedType = index
      this.industryType = item.value;
      // this.$refs.map.test()
    },
    getpath (path) {
      return path ? require('@/assets/images/home/top/' + path + '.png') : '' //path不为null就返回对应的路径
    },
    getpath2 (path) {
      var afterSuffix = '.png'
      if (this.activedType + 1 == path) {
        afterSuffix = '_actived.png'
      }
      return path
        ? require('@/assets/images/home/left/' + path + afterSuffix)
        : '' //path不为null就返回对应的路径
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  padding: 20px;
  .el-aside {
    width: 300px;
    height: 1022px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px #e1e2e9;
    border-radius: 10px;
    padding: 20px 0;
    .daohang {
      padding: 0 20px;
    }
    .typeList {
      padding: 15px 0px 20px 20px;
      display: flex;
      flex-wrap: wrap;
      .itemOne {
        width: 125px;
        height: 72px;
        background: #ffff;
        border-radius: 10px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .icon {
          img {
            width: 26px;
            height: 26px;
          }
        }
        .word {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
        }
      }
      .actived {
        background: #f0f3fe;
        .word {
          color: #4e81fd !important;
        }
      }
    }
  }
  .right-container {
    margin-left: 20px;
    .right-header {
      padding-left: 19px;
      display: flex;
      flex-wrap: wrap;
      height: auto !important;
      padding-top: 10px;
      .dataItem {
        display: flex;
        min-width: 170px;
        width: 14%;
        margin-bottom: 15px;
        img {
          width: 44px;
          height: 44px;
        }
        .right {
          margin-left: 8px;
          .num {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #333333;
            }
          }
          .descr {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
  .el-main {
    height: 904px;
    background: #eef1fa;
    border-radius: 10px 0px 0px 0px;
    padding: 0;
  }
}
</style>
