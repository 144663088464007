import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import layout from "@/layouts/index"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    // component: Home,
    redirect:'/main/chartsB'
    // component: () => import( /* webpackChunkName: "about" */ '../views/IndustryDistributionMap.vue')
  },
  {
    path: '/home',
    name: 'Main',
    component: () => import( /* webpackChunkName: "about" */ '../views/Main.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/main',
    component: layout,
    children: [{
        path: 'chanyeDt',
        name: 'chanyeDt',
        component: () => import('@/views/chanyeDt'),
      },
      {
        path: 'industryMap',
        name: 'IndustryMap',
        component: () => import('../views/IndustryMap.vue')
      },
      {
        path: 'IndustrialChain',
        name: 'IndustrialChain',
        component: () => import('../views/IndustrialChain.vue')
      },
      {
        path: 'IndustryDistributionMap',
        name: 'IndustryDistributionMap',
        component: () => import('../views/IndustryDistributionMap.vue')
      },
      {
        path: 'IndustryHousing',
        name: 'IndustryHousing',
        component: () => import('../views/IndustryHousing.vue')
      },
      {
        path: 'IndustryLanding',
        name: 'IndustryLanding',
        component: () => import('../views/IndustryLanding.vue')
      },
      {
        path: 'enterpriseInfo',
        name: 'enterpriseInfo',
        component: () => import('../views/enterpriseInfo.vue')
      },
      {
        path: 'charts',
        name: 'Charts',
        component: () => import( /* webpackChunkName: "about" */ '../views/Charts.vue')
      },
      {
        path: 'chartsB',
        name: 'chartsB',
        component: () => import( /* webpackChunkName: "about" */ '../views/ChartsB.vue')
      },
      {
        path: 'newchain/index',
        name: 'IndustrialChain',
        component: () => import( /* webpackChunkName: "about" */ '../views/newChain/index.vue')
      },
      {
        path: 'newchain/chainDetail',
        name: 'IndustrialChain',
        component: () => import( /* webpackChunkName: "about" */ '../views/newChain/chainDetail.vue')
      },
      {
        path: 'newchain/chainEnterprise_map',
        name: 'IndustrialChain',
        component: () => import( /* webpackChunkName: "about" */ '../views/newChain/chainEnterprise_map.vue')
      },
      {
        path: 'newchain/chain_relati_Enterprise',
        name: 'IndustrialChain',
        component: () => import( /* webpackChunkName: "about" */ '../views/newChain/chain_relati_Enterprise.vue')
      },
    ],
  },
]

const router = new VueRouter({
  routes
})

export default router