<template>
  <div id="app">
    <!-- <Navbar></Navbar> -->
    <router-view  :key="key"/>
  </div>
</template>

<script>
// import Navbar from '@/layouts/components/Navbar'

export default {
  // components: {
  //   Navbar
  // }
  computed: {
    // curentIndex() {
    //   return this.$route.name
    // },
    key() {
        return this.$route.path
      },
  },
}
</script>
<style>
#app{
  height: 100%;
  width: 100%;
}
</style>
